@import "variables";
@import "mixins";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.text-left {
    text-align: left !important;
}
.text-right {
    text-align: right !important;
}
.text-center {
    text-align: center !important;
}
.text-start{
    text-align: start !important;
}
.align-center {
    align-content: center !important;
}
.align-end {
    align-content: end !important;
}
.item-center{
    display: flex !important;
    align-items: center !important;
}
.text-underline {
    text-decoration: underline !important;
    text-underline-offset: 0.25rem;
}

//paddings
.p-0 {
    padding: 0 !important;
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.pl-0 {
    padding-left: 0 !important;
}
.pl-10{
    padding-left: 10px !important;
}
.px-12{
    padding-left: 12px !important;
    padding-right: 12px !important;
}
.pr-0 {
    padding-right: 0 !important;
}
.pt-0 {
    padding-top: 0 !important;
}
.pb-0 {
    padding-bottom: 0 !important;
}
.pr-10 {
    padding-right: 10px!important;
}
.pgr-10 {
    padding-right: 10px !important;
}
.p-16 {
    padding: 16px !important;
}
.pt-16{
    padding-top: 16px !important;
}
.px-16 {
    padding-left: 16px !important;
    padding-right: 16px !important;
}
.px-28 {
    padding-left: 28px !important;
    padding-right: 28px !important;
}
.py-16 {
    padding-top: 16px;
    padding-bottom: 16px;
}
.p-12 {
    padding: 12px !important;
}
.p-8{
    padding: 8px !important;
}

.p-10{
    padding: 10px !important;
}
.pd-5{
    padding: 5px !important;
}
.p-20 {
    padding: 20px !important;
}
.p-25{
    padding: 25px !important;
}
.pb-10 {
    padding-bottom: 10px !important;
}
.pb-16 {
    padding-bottom: 16px !important;
}

.pb-5 {
    padding-bottom: 5px !important;
}

//margins
.m-0 {
    margin: 0 !important;
}
.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.ml-0 {
    margin-left: 0 !important;
}
.mr-0 {
    margin-right: 0 !important;
}
.mt-0 {
    margin-top: 0 !important;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.mb-5px{
    margin-bottom: 5px !important;
}
.mt-1rem {
    margin-top: 1rem !important;
}
.mt-2rem{
    margin-top: 2rem !important;
}
.mt-16 {
    margin-top: 16px !important;
}
.mt-25{
    margin-top:25px !important;
}
.mt-6 {
    margin-top: 6px !important;
}
.mb-16 {
    margin-bottom: 16px !important;
}
.m-30 {
    margin: 30px;
}
.mt-5px {
    margin-top: 5px !important;
}
.ml-5 {
    margin-left: 5px;
}
.m-10 {
    margin: 10px;
}
.mt-10 {
    margin-top: 10px !important;
}
.mt-15{
    margin-top: 15px !important;
}
.mb-10 {
    margin-bottom: 10px !important;
}
.ml-10 {
    margin-left: 10px !important;
}
.mt-20 {
    margin-top: 20px !important;
}
.mb-20 {
    margin-bottom: 20px !important;
}
.mb-25{
    margin-bottom: 25px !important;
}
.mb-30{
    margin-bottom: 30px !important;
}
.ml-20 {
    margin-left: 20px !important;
}
.ml-24 {
    margin-left: 24px !important;
}
.ml-30{
    margin-left: 30px !important;
}
.ml-20per{
    margin-left: 20% !important;
}
.ml-10per{
    margin-left: 10% !important;
}
.ml-16per{
margin-left: 16% !important;
}
.mr-10 {
    margin-right: 10px !important;
}
.mx-20 {
    margin-right: 20px !important;
    margin-left: 20px !important;
}
.my-16 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
}
.mv-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}
.ml-16 {
    margin-left: 16px !important;
}
.mt-16rem {
    margin-top: 16rem !important;
}
.m-20 {
    margin: 20px !important;
}
.mt-35 {
    margin-top: 35px !important;
}
//font-weights
.fw-400 {
    font-weight: 400 !important;
}
.fw-500 {
    font-weight: 500 !important;
}
.fw-600 {
    font-weight: 600 !important;
}
.fw-700 {
    font-weight: 700 !important;
}
.fw-800{
    font-weight: 800 !important;
}
.fw-900{
    font-weight: 900 !important;
}
.fw-bold{
    font-weight: bold !important;
}
//cursors
.cursor-pointer {
    cursor: pointer;
}
.cursor-not-allowed {
    cursor: not-allowed !important;
}
//pointer-events
.no-pointer-events {
    pointer-events: none !important;
}
.auto-pointer-events {
    pointer-events: auto !important;
}
//required
.required::after {
    content: "*";
    color: $fc-error;
}
//height and width
.full-space {
    width: 100%;
    height: 100%;
}
.full-height {
    height: 100%;
}
.full-width {
    width: 100% !important;
}
.min-height-50 {
    min-height: 50vh !important;
}
.max-height-50 {
    max-height: 50vh !important;
}
 .width-90per{
    width: 90% !important;
 }
 .width-80per{
    width: 80% !important
 }
 .width-70per{
    width: 70% !important
 }
 .width-65per{
    width: 65% !important;
 }
 .width-50per{
    width: 50% !important;
 }
 .width-45per{
    width: 45% !important;
 }
 .width-50vw{
    width: 50vw;
 }
 .width-150{
    width: 150px !important;
 }
.zero-width {
    width: 0;
}
.w-125 {
    width: 125px !important;
}
.tooltip-wd {
    max-width: 400px !important;
  }
//scrolls
.hidden-overflow {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}
.hidden-overflowX {
    overflow-x: hidden;
}
.hidden-overflowY {
    overflow-y: hidden;
}
.auto-overflow {
    overflow-x: auto;
    overflow-y: auto;
}
.auto-overflowX {
    overflow-x: auto;
}
.auto-overflowY {
    overflow-y: auto;
}
.scroll-overflowY {
    overflow-y: scroll;
}
//dispaly
.show {
    display: block;
}
.hide {
    display: none;
}
.d-inline-flex {
    display: inline-flex !important;
}
.display-flex {
    display: flex;
}
.disable-color {
    color: $fc-grey-3 !important;
}
.flex-wrap {
    flex-wrap: wrap;
}
// border-bottom
.underline {
    border-bottom: $fc-grey-3 solid 1px;
  }
//backgrounds
.bg-white {
    background: $fc-white;
}
//colors
.blue-color {
    color: $fc-blue !important;
}
.light-red-color{
    color: $fc-lightred !important;
}
.yellow-color{
    color: $fc-yellow !important;
}
.dark-grey-color {
    color: $fc-grey-dark !important;
}
.grey-1-color {
    color: $fc-grey-8 !important;
}
.grey-8-color{
    color: $fc-grey-7 !important;
} 
.grey-color {
    color: $fc-grey-2;
}
.grey-4-color {
    color: #dbdbdb;
}
.bg-color {
    background: $fc-bg;
}
.white-color {
    color: $fc-white !important;
}
.primary-color {
    color: $fc-blue-primary !important;
}
.success-color {
    color: $fc-success !important;
}
.warning-color {
    color: $fc-warn !important;
}
.error-color {
    color: $fc-error;
}
// text - transforms
.cap-text {
    text-transform: capitalize;
}
//fonts
.f-10 {
    font-size: 10px !important;
  }

  .f-13 {
    font-size: 13px !important;
  }

  .f-15 {
    font-size: 15px !important;
  }
  
  .f-16 {
    font-size: 16px !important;
  }
  
  .f-18 {
    font-size: 18px !important;
  }
  
  .f-20 {
    font-size: 20px !important;
  }

  .f-24 {
    font-size: 24px !important;
  }
  
  .f-30 {
    font-size: 30px !important;
  }
  
  
  .f-12 {
    font-size: 12px !important;
  }
  
  // for clock icon //
  .f-18 {
    font-size: 18px !important;
  }
  
  .f-14 {
    font-size: 14px !important;
  }
  
  .f-35 {
    font-size: 35px !important;
  }
//aligns
.va-middle {
    vertical-align: middle;
}
.va-center{
    vertical-align: center;
}
.display-ellipsis {
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    // width: 70%;
}
.border-line {
    // border-bottom: 1px solid $fc-grey-3 !important;
    border: 1px solid $fc-grey-2 !important;
}
.no-orders-border-line{
    border: 3px solid #D0D5C6 !important;
}
.search-border {
    // border: 0.727px solid $fc-grey-2 !important;
    // box-shadow: 0px 2.906px 2.906px 0px rgba(0, 0, 0, 0.03) !important;
    border: 1px solid $fc-border-color !important;
    width: 100%;
    outline: none;
    height: 38px !important;
}
.login-border {
    border: 1px solid $fc-border-color !important;
    width: 100%;
    outline: none;
    height: 45.5px !important;
}
.br-10{
    border-radius: 10px !important; 
}
.br-5 {
    border-radius: 5px !important;
}
.br-lf-rt-5 {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

.color-text{
    color: #63666A;
}

.isDisabled {
    pointer-events: none;
}

.border-none{
    border: none !important;
}
// .primary-btn,
// .secondary-btn, .tertiary-btn {
// 	min-width: 112px !important;
//     min-height: 34px !important;
// 	line-height: 30px !important;
//     // color: $fc-black !important;
//     // background-color: $fc-white;
//     border-radius: 6px;
//     font-size: 13px;
//     font-weight: 400;
//     padding: 2px 16px;
//     border : 1px solid $fc-janieandjack;
//     word-break: break-all;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: pre;

// }
.secondary-btn {
    @include return-button;
    border: 1px solid #1E293B;
}

.yellow-btn-1 {
	border: 1px solid $fc-yellow;
	background-color: $fc-yellow !important;
    padding: 20px 20px 15px 20px !important;
}

.black-btn {
	border: 1px solid $fc-yellow;
	background-color: $fc-yellow !important;
    // padding: 5px 20px 5px 20px !important;
}

.black-btn-1 {
	// border: 1px solid $fc-black;
	// background-color: $fc-black !important;
    padding: 20px 20px 15px 20px !important;
}

.disabled-btn {
    min-width: 112px !important;
    height: 34px;
	color: #e1e1e1 !important;
    border: 1px solid #63666a;
	background-color: #63666a !important;
    border-radius: 6px;
	cursor: not-allowed !important;
    pointer-events: none !important;
    opacity: 0.3;
}
.disabled{
    cursor: not-allowed !important;
    pointer-events: none !important;
    opacity: 0.5;
}

.card {
    border: 1px solid $fc-border-color !important;
    border-radius: 5px !important;
    box-shadow: 0px 1px 2px 0px #33415614 !important;
}

.line {
    border-top: 1px solid $fc-border-color ;
}
.dashed-line{
    border-top-style: dashed !important;
    border-width: 1px !important;
    border-color: $fc-border-color !important
 
}
.bottom-dashed-line{
    border-bottom-style: dashed !important;
    border-width: 1px !important;
    border-color: $fc-border-color !important;
}
.text-color{
    color: $fc-black !important;
}
.box-shadow-none{
    box-shadow: none !important;
}
.float-right{
  float: right;
}
.float-left{
    float: left;
  }
.default-color{
    color: $fc-black !important;
}

// .br-bl-br-20{
//     border-bottom-left-radius: 20px;
//     border-bottom-right-radius: 20px;

// }
.op-5{
    opacity: 0.5 !important;
}

.width-fit-content{
width: fit-content !important;
}
.pl-10-pr-12{
    padding-left: 10px !important;
    padding-right: 12px !important; 
}
.select-h-35{
    max-height: 35px !important;
}
@media only screen and (max-width:1199.98px) {
    input,
    textarea {
        font-size: 16px !important;
    }
    .pgr-10 {
        padding-right: 0 !important;
    }
    .width-fit-content{
        width: auto !important;
        }
    .px-28 {
            padding-left: 12px !important;
            padding-right: 12px !important;
        }
}
@media only screen and (max-width:991.98px) {
    .tmb10 {
        margin-bottom: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px){
    .w-125{
         width: 118px !important;
     }
     .px-16 {
        padding-left: 0!important;
        padding-right: 0!important;
    }
    .img-block{
        width:70px !important;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767.98px){
    .w-125{
         width: 118px !important;
     }
    .mm-t10 {
        margin-top: 10px !important;
    }
    .mm-l0 {
        margin-left: 0 !important;
    }
  
  
}
@media only screen and (max-width:767.98px) {
    
    .img-block{
        width: 60px !important;
       }
    .mml5 {
        margin-left: 5px !important;
    }
    .mmt-5px {
        margin-top: 5px !important;
    }
    .px-16 {
        padding-left: 0!important;
        padding-right: 0!important;
    }
    .px-12 {
        padding-left: 7px !important;
        padding-right: 7px !important;
    }
    .mpx-5 {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
    .hide-m {
        display: none !important;
    }
    .mdisplay-flex {
        display: flex;
    }
    .mm-t16 {
        margin-top: 16px !important;
    }
    
}
@media only screen and (max-width:575.98px) {
    .tooltip-wd {
      max-width: 200px !important;
      max-height: 200px !important;
      overflow: auto !important;
    }
    .mm-l16 {
        margin-left: 16px;
    }
    .mm-x0 {
        margin-right: 10px !important;
        margin-left: 10px !important;
    }
    .mm-t40 {
        margin-top: 40px !important;
    }
    .w-125 {
        width: 112px !important;
    }
    .mml0 {
        margin-left: 0px !important
    }
    .mmr0 {
        margin-right: 0px !important
    }
    .mmb10 {
        margin-bottom: 10px !important;
    }
}

/* HTML: <div class="loader"></div> */
.loader {
    position: relative;
    text-align: center;
    margin: auto;
    width: fit-content;
    font-weight: bold;
    font-family: monospace;
    font-size: 20px;
    color: #0000;
    background: linear-gradient(90deg, #9B9A4C calc(50% + 0.5ch),#000 0) right/calc(200% + 1ch) 100%;
    -webkit-background-clip: text;
            background-clip: text;
    animation: l7 2s infinite steps(11);
  }
  // #5385A6
//   .loader:before {
//     content:"Loading...";
//   }
  @keyframes l7 {to{background-position: left}}

//<-------------------------retailer based buttons---------------------------------->

//<-----------------janie and jack starts------------------->
.janieandjack {
    color: $fc-janieandjack !important;
}
.janieandjack-red-color {
    color: $fc-janieandjack-red !important;
}
.janieandjack-btn{
    @include return-button;
    background-color: $fc-janieandjack !important;
    color: $fc-white !important;
    // border: 1px solid $fc-janieandjack !important;
}
.janieandjack-btn:hover {
    background-color: #071d36 !important;
    border-color: #071d36;
}
.janieandjack-btn:disabled,.janieandjack-btn.disabled, .disable-btn {
    color: #e1e1e1 !important;
    background-color: #63666a !important;
    border-color: #63666a !important;
    // opacity: 0.3 !important;
}
.disable-btn:hover{
    color: #e1e1e1 !important;
    background-color: #63666a !important;
    border-color: #63666a !important;
    opacity: 0.3 !important;
}
.janieandjack.tertiary-btn{
    @include return-button;
    background-color: $fc-white !important;
    color: $fc-janieandjack !important;
    border: 1px solid $fc-janieandjack;
}

//<-----------------janie and jack ends------------------->

//<-----------------shop premium outlets starts------------------->
.shopsimon {
    color: $fc-shopsimon !important;
}
.shopsimon-btn{
    @include return-button;
    background-color: $fc-shopsimon !important;
    color: $fc-white !important;
}
.shopsimon-btn:hover {
    background-color: #5c5c5c !important;
    border-color: #5c5c5c;
}
.shopsimon-btn:disabled,.shopsimon-btn.disabled, .disable-btn {
    color: #e1e1e1 !important;
    background-color: #63666a !important;
    border-color: #63666a !important;
    opacity: 0.3 !important;
}
.shopsimon.tertiary-btn{
    @include return-button;
    background-color: $fc-white !important;
    color: $fc-shopsimon !important;
    border: 1px solid $fc-shopsimon;
}

//<-----------------shop premium outlets ends------------------->

//<-----------------wear-humans starts------------------->
.wearhumans-btn {
	// border: 1px solid $fc-yellow;
    @include return-button;
    background-color: $fc-wearhumans !important;
    color: #FFFFFF !important;
	// background-color: $fc-yellow !important;
    // padding: 5px 20px 5px 20px !important;
}

.wearhumans-btn:hover {
    background-color: #B38705 !important;
    // border-color: $fc-wearhumans;
}
.wearhumans-btn:disabled,.wearhumans-btn.disabled, .disable-btn {
    color: #e1e1e1 !important;
    background-color: #63666a !important;
    // border-color: #63666a !important;
    opacity: 0.3 !important;
}
.disable-btn:hover{
    color: #e1e1e1 !important;
    background-color: #63666a !important;
    // border-color: #63666a !important;
    opacity: 0.3 !important;
}
.wearhumans.tertiary-btn{
    @include return-button;
    background-color: $fc-white !important;
    color: #4B5675 !important;
    border: 1px solid #DBDFE9;
}

//<-----------------wear-humans ends------------------->

//<-----------------travel-pro starts------------------->
.travelpro {
    color: $fc-travelpro !important;
}
.travelpro-red-color {
    color: $fc-travelpro-red !important;
}
.travelpro-btn{
    @include return-button;
    background-color: $fc-travelpro !important;
    color: $fc-white !important;
    // border: 1px solid $fc-travelpro !important;
}
.travelpro-btn:hover {
    background-color: #071d36 !important;
    border-color: #071d36;
}
.travelpro-btn:disabled,.travelpro-btn.disabled, .disable-btn {
    color: #e1e1e1 !important;
    background-color: #63666a !important;
    border-color: #63666a !important;
    // opacity: 0.3 !important;
}
.disable-btn:hover{
    color: #e1e1e1 !important;
    background-color: #63666a !important;
    border-color: #63666a !important;
    opacity: 0.3 !important;
}
.travelpro.tertiary-btn{
    @include return-button;
    background-color: $fc-white !important;
    color: $fc-travelpro !important;
    border: 1px solid $fc-travelpro;
}


//<-----------------travel-pro ends------------------->


//<-----------------fluent-commerce starts------------------->
.fluentcommerce {
    color: $fc-fluentcommerce !important;

}
.fluentcommerce-btn{
    @include return-button;
    background-color: $fc-fluentcommerce !important;
    color: $fc-white !important;
    // border: 1px solid $fc-fluentcommerce !important;
}
// .fluentcommerce-btn:hover {
//     background-color: #071d36 !important;
//     border-color: #071d36;
// }
.fluentcommerce-btn:disabled,.fluentcommerce-btn.disabled, .disable-btn {
    color: #e1e1e1 !important;
    background-color: #63666a !important;
    border-color: #63666a !important;
    opacity: 0.3 !important;
}
.fluentcommerce.tertiary-btn{
    @include return-button;
    background-color: $fc-white !important;
    color: $fc-fluentcommerce !important;
    border: 1px solid $fc-fluentcommerce;
}

//<-----------------fluent-commerce ends------------------->

//<-----------------hatch-collections starts------------------->
.hatchcollections {
    color: $fc-hatchcollections !important;

}
.hatchcollections-btn{
    @include return-button;
    background-color: $fc-hatchcollections !important;
    color: $fc-white !important;
    // border: 1px solid $fc-hatchcollections !important;
}
// .hatchcollections-btn:hover {
//     background-color: #071d36 !important;
//     border-color: #071d36;
// }
.hatchcollections-btn:disabled,.hatchcollections-btn.disabled, .disable-btn {
    color: #e1e1e1 !important;
    background-color: #63666a !important;
    border-color: #63666a !important;
    opacity: 0.3 !important;
}
.hatchcollections.tertiary-btn{
    @include return-button;
    background-color: $fc-white !important;
    color: $fc-hatchcollections !important;
    border: 1px solid $fc-hatchcollections;
}

//<-----------------hatch-collections ends------------------->

