@import "../styles/variables";

// .footer.janieandjack,.prymary-btn{
//   background-color: #efefef !important;
//   color: #63666a !important
// }
.footer_social {
  display: flex;
  column-gap: 1.46rem;
  /* margin-top: 3rem; */
  list-style: none;
}
 .travelpro.footer {
    background: $fc-travelpro !important;
    color: white;
    height: auto;
    vertical-align: middle;
    padding-bottom: 0px;
}

.janieandjack.footer{
   background: #efefef !important;
    color: #63666a;
    height: auto;
    vertical-align: middle;
    padding-bottom: 0px;
}
.shopsimon.footer{
  background: #f7f7f7 !important;
  color: #000000;
  height: auto;
  vertical-align: middle;
  padding-bottom: 0px;
}
.wearhumans.footer{
  background: #111111 !important;
  color: white;
}

.fluentcommerce.footer{
  background: #f7f7f7 !important;
  color: #222 !important;
  height: auto;
  vertical-align: middle;
  padding-bottom: 0px; 
}
.hatchcollections.footer{
  background-color: #fdf5f5 !important;
  color: #222 !important;
  height: auto;
  vertical-align: middle;
  padding-bottom: 0px; 
}

.accordion {
    text-align: left;
}

.mobilefooter {
  display: none;
}
.footer .card {
    border: none !important;
}

.wearhumans.card-header.title {
    font-size: 14px;
    // color: #000;
    background-color: #000000 !important;
    letter-spacing: 0.1em;
}

.shopsimon.card-header.title {
  font-size: 14px;
  // color: #000;
  background-color: #f7f7f7 !important;
  letter-spacing: 0.1em;
}
.janieandjack.card-header.title {
  font-size: 14px;
  // color: #000;
  background-color: #63666a !important;
  letter-spacing: 0.1em;
}
.travelpro.card-header.title {
  font-size: 14px;
  // color: #000;
  background-color: #1e284d !important;
  letter-spacing: 0.1em;
}
.hatchcollections.card-header.title {
  color: #2d2d2d !important;
}
.hatchcollections.card-header {
  background-color: #fdf5f5 !important;
}
.hatchcollections.card-body {
  background-color: #fdf5f5 !important;
}
 .card-header .title {
  color: #ffffff;
}
.card-header .accicon {
    float: right;
    font-size: 20px;
    width: 1.2em;
    color: #ffffff;
}

.janieandjack .card-header .accicon,
.fluentcommerce .card-header .accicon,
.hatchcollections .card-header .accicon,
.shopsimon .card-header .accicon,
.travelpro .card-header .accicon {
  color: #000000 !important;
}
.card-header:not(.collapsed) .rotate-icon {
    transform: rotate(180deg);
}
.card-body {
  padding: 0 !important;
}
.wearhumans.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    background-color: #000000;
}
 .shopsimon.card-body{
   flex: 1 1 auto;
    min-height: 1px;
    background-color: #f7f7f7 !important;
 }
 .janieandjack.card-body{
  flex: 1 1 auto;
  min-height: 1px;
  background-color: #efefef !important;
 }

 .travelpro.card-body{
  flex: 1 1 auto;
  min-height: 1px;
  background-color: $fc-travelpro !important;
 }
//  .fluentcommerce.card-body{
//   padding: 0.25rem;
//  }

 .texthrlink {
  display: inline-block;
  margin-top: 10px;
  font-size: 17px;
  color:#ffffff;
  // letter-spacing: 0.5px;
  font-weight: 400;
  line-height: 2;
  cursor: pointer;
  text-decoration: none;
}

 .janieandjack .texthrlink {
    display: inline-block;
    margin-top: 10px;
    font-size: 12px;
    color:#63666a;
    // letter-spacing: 0.5px;
    font-weight: 500;
    line-height: 2;
    cursor: pointer;
    text-decoration: none;
    font-family: 'Nunito', sans-serif !important;
}

.janieandjack.texthrlink:hover {
  color:  $fc-janieandjack!important;
  text-decoration: underline;
}

.fluentcommerce.texthrlink {
  display: inline-block;
  margin-top: 10px;
  font-size: 12px;
  color:#63666a !important;
  font-weight: 500;
  line-height: 2;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Nunito', sans-serif !important;
}
.janieandjack .texthrlink {
  display: inline-block;
  margin-top: 10px;
  font-size: 12px;
  color:#63666a;
  // letter-spacing: 0.5px;
  font-weight: 500;
  line-height: 2;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Nunito', sans-serif !important;
}

.janieandjack.texthrlink:hover {
color:  $fc-janieandjack!important;
text-decoration: underline;
}

.travelpro.texthrlink {
display: inline-block;
margin-top: 10px;
font-size: 12px;
color:#ffffff !important;
font-weight: 500;
line-height: 2;
cursor: pointer;
text-decoration: none;
font-family: 'Nunito', sans-serif !important;
}

.fluentcommerce.texthrlink:hover {
color:  $fc-fluentcommerce !important;
// text-decoration: underline;
}



.line1 {
    border: 1px solid #cdcdcd;
    margin: 0px 13px 0px 13px;
    width: auto;
}
.text-fw500{
  font-weight: 600 !important;
  font-size: 18px !important;
  color: #ffffff !important;
  // letter-spacing: 1.5px !important;
  }

.janieandjack .text-fw500{
font-weight: 500 !important;
font-size: 14px !important;
color: #63666a !important;
letter-spacing: 0.28em !important;
font-family: 'Nunito', sans-serif !important;
}
.travelpro .text-fw500{
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #ffffff !important;
  letter-spacing: 0.28em !important;
  font-family: 'Nunito', sans-serif !important;
  }

.fluentcommerce.text-fw500{
  font-weight: 500 !important;
  font-size: 14px !important;
  color: $fc-fluentcommerce !important;
  font-family: 'Inter', sans-serif !important;
  }

  .footertext {
    text-align: left;
    font-size: 17px !important;
    margin-top: 32px !important;
    padding-left: 30px !important;
    padding-right: 0px !important;
  }
  
  .footertext1 {
    color: #63666a;
    font-size: 14px;
    margin-left: 12px;
  }
.footer-align {
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.footer_link {
    text-decoration: none;
    color: #ffffff !important;
    font-size: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: normal;
    padding-left: 0px;
}
.janieandjack .footer_link {
  color:#63666a !important;
}
.hatchcollections .footer_link,
.shopsimon .footer_link {
  color: #000000 !important;
}
.logo-foot {
    height: 25px;
}

.fenix-logo-footer {
  padding-right: 10%;
  padding-top: 20px;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .line1 {
    margin: 0px;
  }

  // .texthrlink {
  //   letter-spacing: 0.1em;
  //   margin-left: 16px;
  // }

  .wearhumans .card-header .title {
    font-size: 14px;
    color: #ffffff;
    background: #000000 !important;
    letter-spacing: 0.1em;
    font-weight: 500;
  }

  .shopsimon .card-header .title {
    font-size: 14px;
    color: #000000;
    background: #f7f7f7 !important;
    letter-spacing: 0.1em;
    font-weight: 500;
  }
  .janieandjack .card-header .title {
    font-size: 14px;
    color: #ffffff;
    background: #efefef !important;
    letter-spacing: 0.1em;
    font-weight: 500;
  }

  .travelpro .card-header .title {
    font-size: 14px;
    color: #ffffff;
    background: $fc-travelpro !important;
    letter-spacing: 0.1em;
    font-weight: 500;
  }
  .footer {
    padding-bottom: 60px;
  }

  .footer-align {
    text-align: start;
    margin-left: 0;
  }

  .newfooter {
    display: none;
  }

  .mobilefooter {
    display: block !important;
    margin-top: -21px;
    padding-bottom: 10px;
    padding-right: 0px;
    padding-left: 0px;
  }
  // .janieandjack.mobilefooter {
  //   background-color: #efefef !important;
  // }
  // .wearhumans.mobilefooter {
  //   background-color: #000000 !important;
  // }

  // .footertext {
  //   margin-top: 0px;
  // }

  .shopsimon .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0.25rem;
    background-color: #f7f7f7 !important;
  }

  .wearhumans .card-body{
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0.25rem;
    background-color: #000000 !important;
  }

  .janieandjack .card-body{
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0.25rem;
    background-color: #efefef !important;

  }

  .travelpro .card-body{
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0.25rem;
    background-color: $fc-travelpro !important;
  }

  .foot-contact {
    padding-left: 0;
  }

  .wearhumans .accordion .card .card-header {
    background-color: #000000 !important;
  }

  .janieandjack .accordion .card .card-header{
    background-color: #efefef !important;
  }
  .shopsimon .accordion .card .card-header{
    background-color: #f7f7f7 !important;
  }
  .travelpro .accordion .card .card-header{
    background-color: $fc-travelpro !important;
  }


  .footertext1 {
    font-size: 12px;
    margin-left: 0px;
  }

  footer {
    height: auto;
    vertical-align: middle;
  }

  .accordion {
    text-align: left;
  }

  .foot-contact {
    margin: auto;
    align-items: center !important;
    padding-top: 7px;
    margin-left: 17px;
  }

  .footer_link {
    text-decoration: none;
    font-size: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: normal;
    padding-left: 0px;
  }

  .fenix-logo-footer {
    padding-right: 0px;
    margin: auto;
    text-align: center !important;
    align-items: center !important;
    color: #000000
  }
}

@media screen and (max-width: 767px) and (min-width: 575px) {
  // .footer {
  //   height: 525px !important;
  //   vertical-align: middle;
  // }

  .footer-align {
    text-align: end;
  }

  .footertext1 {
    padding: 0px 0px;
    font-size: 12px;
    margin: 16px;
  }

  .footertext {
    text-align: left;
  }

}

@media only screen and (min-width: 834px) and (max-width: 1112px) {
  .newfooter {
    display: none;
  }

  .mobilefooter {
    display: block !important;
  }
}

@media screen and (max-width: 575px) and (min-width: 484px) {
  .main-header {
    width: 100% !important;
  }
}

@media screen and (max-width: 767px) and (min-width: 576px) {
  .main-header {
    width: 100%;
  }

  .foot-contact {
    margin: auto;
    padding-top: 25px;
    /* text-align: right !important; */
  }

  .fenix-logo-footer {
    text-align: left !important;
    align-items: left !important;
  }
}

@media only screen and (max-width: 812px) and (min-width: 374px) {
  .footer {
    height: auto;
    vertical-align: middle;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .mobilefooter {
    display: block;
  }

  .newfooter {
    display: none;
  }

  .wearhumans .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    background-color: #000000 !important;
  }

  .shopsimon .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    background-color: #f7f7f7 !important;
  }

  .janieandjack .card-body{
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    // background-color: #1b375d;

  }

  .travelpro .card-body{
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    background-color: #1e284d !important;
  }
}
@media only screen and (max-width: 1199px) {
  
  .janieandjack.mobilefooter {
    background-color: #efefef !important;
  }
  .shopsimon.mobilefooter {
    background-color: #f7f7f7 !important;
  }
  .fluentcommerce .sm-icons,
  .shopsimon .sm-icons {
    background-color: #f7f7f7 !important;
    padding-left: 14px;
  }
  .hatchcollections .sm-icons {
    background-color: #fdf5f5 !important;
    padding-left: 14px;
  }
  .hatchcollections.mobilefooter {
    background-color: #fdf5f5 !important;
  }
  .wearhumans.mobilefooter {
    background-color: #000000 !important;
  }
  .travelpro.mobilefooter {
    background-color: #000000 !important;
  }
.janieandjack.footer{
  background-color: #efefef !important;
}
.footer_social {
  margin-left: 16px;
}
.show.display-cell{
  display: table-cell !important;
}
}
@media only screen and (min-width: 768px) and (max-width: 1199px) {

  .foot-contact {
    padding-bottom: 10px;
  }

  .mobilefooter {
    display: block;
    background-color: #111111;
  }
  // .show{
  //   display: table-cell !important;
  // }

  .wearhumans.card-header {
    background-color: #000000 !important;
  }
  .shopsimon.card-header {
    background-color: #f7f7f7!important;
  }
  .janieandjack.card-header {
    background-color: #efefef !important;
  } 
  .travelpro.card-header {
    background-color: $fc-travelpro !important;
  }

  .newfooter {
    display: none;
  }
  .footertext1 {
    color: #ffffff;
    font-size: 14px;
    margin-left: 16px;
  }

  .footertext {
    text-align: left;
    font-size: 17px;
    // margin-top: -30px;
  }

  footer {
    margin-top: -60px;
    background: none;
  }

  .mb-0 {
    margin-left: -16px
  }

  // .wearhumans .card-header {
  //   background-color: #000000;
  // }


  // .card-header {
  //   font-size: 14px;
  //   color: #FFFFFF;
  // }

  // .wearhumans .card-header.title {
  //   font-size: 14px;
  //   color: #000;
  //   letter-spacing: 0.1em;
  // }

  // .texthrlink {
  //   font-size: 14px;
  //   letter-spacing: 0.1em;
  //   margin-left: 18px;
  // }

  .line1 {
    margin: 0px;
  }
}


