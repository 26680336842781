.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 50vh;
  max-width: 500px;
  margin: 0 auto;
  padding: 0 20px;
}

.error-code {
  font-size: 100px;
  // color: #e74c3c;
  margin: 0;
}

.error-message {
  font-size: 24px;
  // color: #C64B42 !important;
  font-weight: 600;
  color: #333;
  font-style: italic;
}

.error-description {
  font-size: 16px;
  color: #666;
  margin-top: 10px;
}

.home-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #c0392b;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;

  &:hover {
      background-color: #e74c3c;
  }
}
