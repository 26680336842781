@import './../../../styles/variables.scss';
@import './../../../styles/utilities';

.mb-30 {
    margin-bottom: 30px !important;
}
.mt-45 {
    margin-top: 45px;
}
.ml-26 {
    margin-left: 26px;
}
.py-10 {
    padding: 10px 0 !important;
}
.wearhumans .textcolor{
    color: #FFFFFF !important
}
.janieandjack .textcolor{
    color: #FFFFFF !important;
}
.fluentcommerce .textcolor{
    color: #FFFFFF !important;
}
.travelpro .textcolor{
    color: #FFFFFF !important;
}

.hatchcollections .textcolor{
    color: #FFFFFF !important;

}
.custom-file-upload {
    display: inline-block !important;
    cursor: pointer !important;
    color: $fc-blue-primary !important;
    text-decoration: underline !important;
}

.acc-btn.show.collapsed, .acc-btn.collapsed{
    border: 1px solid grey;
    border-radius: 10px !important;
}
.acc-btn.show{
    border-radius: 10px 10px 0px 0px !important;
}

input[type="file"] {
    display: none !important;
  }
.border-right{
    border-right: 1px solid grey;
}
.vertical-align-middle{
    align-items: center;
    display: flex;
}

.icon-size .css-i4bv87-MuiSvgIcon-root {
    width: 25px !important;
  }