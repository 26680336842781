@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import "_utilities";
@import "_override";

// html {
//   overflow-x: hidden !important;
// }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Inter';
  // overflow-x: hidden !important;
  // overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  font-family: "Nunito Sans", sans-serif;
}
div {
  color: #000000;
}

