@import "../styles/variables";

.nav-wrapper {
ul li {
  display: inline-flex;
  margin: 7px 10px;
}

nav {
  height: auto;
  min-height: 60px;
  padding-bottom: 10px;
  // padding-left: 10px;
  margin-bottom: 0;
  border-bottom: none;
  // padding-right: 10px;
  border-radius: 0px;
}

.logo-size{
  max-height: 50px !important;
}
.janieandjack,.prymary-btn{
  background-color: #ffffff !important;
  color: #63666a !important;
}

navbar-toggler {
  border: none!important;
}

// .nav-item {
//   padding-top: 12px;
// }

.nav-item {
  padding-top: 4px;
}

// .navbar-expand-md .navbar-nav .nav-link {
//   padding-right: 0.4rem;
//   padding-left: 0.4rem;
// }
// .navbar-expand-md .navbar-nav .nav-link {
//   padding-right: 1.2rem;
//   padding-left: 1.2rem;
// }

.shopsimon.nav-link {
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: Inter;
  // letter-spacing: 1px;
  // color: #000000 !important;
  color: #ffffff !important;
}
.janieandjack.nav-link {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: 'Nunito', sans-serif !important;
  // letter-spacing: 1px;
  // color: #000000 !important;
  color: #63666a !important;
  text-transform: uppercase;
}
.janieandjack.nav-link:hover {
  color: #071d36!important;
  text-decoration: underline;
  text-underline-offset: 0.25rem;
}
.travelpro.nav-link {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: 'Nunito', sans-serif !important;
  // letter-spacing: 1px;
  // color: #000000 !important;
  color: #63666a !important;
  text-transform: uppercase;
}
.travelpro.nav-link:hover {
  color: #071d36!important;
  text-decoration: underline;
  text-underline-offset: 0.25rem;
}
.wearhumans.nav-link {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: 'Nunito', sans-serif !important;
  // letter-spacing: 1px;
  // color: #000000 !important;
  color: #FFFFFF !important;
  text-transform: uppercase;
}
.wearhumans.nav-link:hover {
  color: #FFFFFF!important;
  text-decoration: underline;
  text-underline-offset: 0.25rem;
}
.ham-btn {
  position: absolute;
  top: 25px;
  color: white !important;
  border: none!important;
}

.ham-span {
  font-size: 15px;
  color: white;
}

.f-icon {
  color: #000;
  font-size: 28px;
}

.d-main-logo {
  display: block;
  margin: auto;
  // padding: 12px 6px 10px 6px;
  background: #ffffff;
}
.shopsimon.mob-nav {
  // background: #ffc727;
  background: #000;
  border: none;
}
.janieandjack.mob-nav{
  background: $fc-janieandjack;
  border: none;
}

.main-logo {
  width: 300px;
  // height: 39px;
}

.mobile-logo {
  display: none;
}

.width-98per{
  width: 98% !important;
}
@media only screen and (max-width: 767px) {
  .d-main-logo {
    display: none !important;
  }
  // .ham-span {
  //   margin-left: 26px;
  // }
  .mobile-logo {
    display: block;
    height: 50px;
    width: 225px;
    top: 15px;
    position: absolute;
    left: 33%;
    background: #ffffff !important;
  }
  .janieandjack.mobile-logo {
    display: block;
    height: auto;
    width: 200px;
    top: 29px;
    position: absolute;
    // left: 33%;
  }
  .hatchcollections.mobile-logo {
    height: auto;
    top: 22px;
  }
 .wearhumans.mobile-logo {
  display: block;
  height: 35px;
  width: 225px;
  top: 15px;
  position: absolute;
  left: 20%;
  background:none !important;
}

  .mob-nav {
    height: auto !important;
    margin-left: 0px;
    margin-top: 60px;
    border: none !important;
    background: #ffffff;
  }

  .nav-link {
    color: #000000;
    padding: 0 10px;
  }
  .nav-wrapper {
    nav {
      min-height: 85px;
      background-color: #ffffff;
      box-shadow:
        0px 2px 20px rgb(0 0 0 / 20%),
        0px 1px 6px rgb(0 0 0 / 10%);
    }
  }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.4rem;
    padding-left: 0.4rem;
    font-size: 12px !important;
  }
}

@media screen and (max-width: 767px) and (min-width: 576px) {
  // .ham-span {
  //   margin-left: 26px;
  // }
  .mobile-logo {
    position: absolute;
    display: block !important;
    left: 37%;
  }
}

// @media only screen and (max-width: 736px) and (min-width: 735px) {
//   .ham-span {
//     margin-left: 26px;
//   }
// }

// @media only screen and (max-width: 300px) {
//   .mobile-logo {
//     width: 200px;
//     left: 22%;
//   }
// }
@media screen and (max-width: 500px) and (min-width: 386px) {
  .mobile-logo {
    position: absolute;
    left: 25%;
  }
}
@media screen and (max-width: 385px) and (min-width: 300px) {
  .mobile-logo {
    left: 22%;
  }
}

// @media only screen and (max-width: 812px) and (min-width: 374px) {
//   nav {
//     position: relative;
//     right: 2px;
//   }

//   .footer {
//     height: auto;
//     vertical-align: middle;
//     padding-bottom: 40px;
//   }

//   .nav-link {
//     margin-right: 5px;
//   }
// }

// @media only screen and (max-width: 823px) and (min-width: 411px) {
//   nav {
//     position: relative;
//     right: 1px;
//   }
// }

// @media only screen and (max-width: 768px) and (min-width: 767px) {
//   .nav-link {
//     margin-right: 1px;
//   }

//   nav {
//     padding-left: 2px;
//   }
// }

// @media only screen and (max-width: 1366px) and (min-width: 1024px) {
//   .nav-link {
//     position: relative;
//     right: -32px;
//   }
// }

// @media only screen and (max-width: 1024px) and (min-width: 768px) {
//   .nav-link {
//     position: relative;
//     right: -11px;
//   }
// }
}
.janieandjackwrap, .hatchcollectionswrap .travelprowrapS {
  background-color: #ffffff !important;
  box-shadow: 0 4px 2px -2px #e0e3e7 !important;
  margin-bottom: 25px;
}
.wearhumanswrap{
  background-color: $fc-wearhumans;
  box-shadow: 0 4px 2px -2px #e0e3e7 !important;
  margin-bottom: 25px;
}
.janieandjacknote-wrapper {
  background-color: $fc-janieandjack !important;
  margin-bottom: 10px;
  color: $fc-white !important;
}
.fluentcommercewrap{
  background-color: #2F1963 !important;
  // box-shadow: 0 4px 2px -2px #e0e3e7 !important;
  margin-bottom: 25px;
  color: white !important;
}
.shopsimonwrap .logo-size {
  max-width: 250px !important;
  max-height: auto !important;
}
@media only screen and (max-width: 767px) {
  .fluentcommerce.mob-nav{
    background: #2F1963 !important;
  }
  .fluentcommerce.mobile-logo{
    height: auto !important;
  }
  .fluentcommercewrap {
    background-color: $fc-white !important;
  }
}
