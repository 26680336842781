$fc-blue-primary: #024ca0;
$fc-yellow: #FFC107;
$fc-warn: #F97608;
$fc-lightred: #E52B01;
$fc-bg: #F7F7F6;
$fc-white: #FFFFFF;
$fc-black: #000000;
$fc-grey-1: #616161;
$fc-grey-7:#3C4242;
$fc-grey-8:#807D7E;
$fc-grey-2:#959595;
$fc-blue: #0091B5;
// borders & disable
$fc-grey-3: #c4c4c4;
$fc-grey-dark: #78829D;
$fc-border-color: #DBDFE9;
// background
$fc-grey-4:#dbdbdb;
$fc-grey-5:#bdbdbd;
$fc-grey-6:#E0E0E0;
$fc-error: #b00020;
$fc-success: #007E33;
// sucessText color
$fc-light-green: #08C258;
// old colors
$btn-blue:#0B6ECF;
$fc-blue-dark:#1C3960;
// brand colors
$fc-wearhumans: #FFC107;
$fc-janieandjack: #1B375D;
$fc-janieandjack-red:#C8102E;
$fc-fluentcommerce: rgb(116, 88, 197);
$fc-shopsimon: #000000;
$fc-hatchcollections: #2d2d2d;
$fc-travelpro: #000000;
$fc-travelpro-red:#C8102E;






