@import 'variables.scss';

.css-1q2h7u5 {
  min-height: 30px !important;
  text-transform: none !important;
  line-height: 0.25 !important;
}
.css-10by3a5-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem.Mui-selected.Mui-focus,
.css-10by3a5-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem.Mui-selected.Mui-selected,
.css-10by3a5-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem.Mui-selected.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.css-1gs62wq:hover,
.css-10by3a5-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem:hover {
  background-color: transparent !important;
}
.css-1gs62wq.Mui-selected.Mui-selected,
.css-1gs62wq.Mui-selected.Mui-focus,
.css-1gs62wq.Mui-selected.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.css-1chpzqh,
.css-levciy-MuiTablePagination-displayedRows,
.css-pdct74-MuiTablePagination-selectLabel {
  margin-bottom: 0 !important;
 }
// .css-1chpzqh,
// .css-levciy-MuiTablePagination-displayedRows {
//   margin-bottom: 0 !important;
// }
.css-8nphli .MuiTablePagination-actions {
  margin-left: 8px !important;
}
.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  margin-right: 16px !important;
  }
.css-182didf.MuiSelect-select {
  margin-right: 16px !important;
  }
.css-rmmij8 {
  margin-right: 8px !important;
}
.css-1oraz3g .MuiTablePagination-select:hover,
.css-1oraz3g .MuiTablePagination-select:focus {
  outline: none !important;
  border-color: $fc-grey-2 !important;
  border-radius: 5px !important;
  background-color: transparent !important;
}
.css-1cccqvr:focus{
  background-color: transparent !important;
}
.css-1oraz3g .MuiTablePagination-toolbar {
  flex-direction: row !important;
  align-items: center !important;
}
.MuiTablePagination-root .MuiTablePagination-select {
  border: 1px solid $fc-grey-2 !important;
  border-radius: 6px !important;
}
.css-1deacqj,
.MuiTablePagination-actions .css-zylse7-MuiButtonBase-root-MuiIconButton-root {
  padding: 0 !important;
}
// .css-1oraz3g .MuiTablePagination-select {
//   border: 1px solid $fc-grey-2 !important;
// }

// .css-h3u2z4-MuiTablePagination-root .MuiTablePagination-select {
//   border: 1px solid $fc-grey-2 !important;
//   border-radius: 5px !important;
// }
.css-194a1fa-MuiSelect-select-MuiInputBase-input:hover,
.css-194a1fa-MuiSelect-select-MuiInputBase-input:focus {
  background-color: transparent !important;
  outline: none !important;
}
.css-h3u2z4-MuiTablePagination-root .MuiTablePagination-toolbar {
  -webkit-flex-direction: row !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}
.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
  margin-left: 8px !important;
}
.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  margin-right: 8px !important;
}
.css-ttwr4n {
  height: 0 !important;
  width: 0 !important;
}
.css-1l4w6pd {
  -webkit-box-pack: left !important;
  justify-content: left !important;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding: 0 9px !important;
  }
  .css-j204z7-MuiFormControlLabel-root {
  vertical-align: top !important;
  }
  .css-1a5icme {
    padding: 0 9px !important;
  }  
  .css-1jaw3da { 
    vertical-align: top !important;
  }
// .css-twpmdu .MuiTablePagination-select:focus {
//    outline: none !important; 
//    border-color: #959595 !important; 
// }
// .css-twpmdu .MuiTablePagination-select:hover {
//   background-color: transparent !important;
//   border-color: #959595 !important;
// }
// .css-twpmdu .MuiTablePagination-select {
//   border: 1px solid #959595 !important;
//   cursor: pointer !important;
// }
// .css-twpmdu .MuiTablePagination-toolbar {
//   -webkit-flex-direction: row !important;
//   -ms-flex-direction: row !important;
//   flex-direction: row !important;
//   -webkit-align-items: center !important;
//   -webkit-box-align: center !important;
//   -ms-flex-align: center !important;
//   align-items: center !important;
//   // gap: 10px 52px !important
// }
.css-orq8zk {
  min-height: 36px !important;
}
.css-dms7x9 {
  margin: 0 !important;
}
.css-zun73v.Mui-disabled{
  pointer-events: all !important;
}
.css-igs3ac {
  border-color: transparent !important;
}
.css-fvipm8.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}
// .css-vubbuv {
//   width: 16px !important;
// }
.css-1w4vsez-MuiFormControl-root { 
  margin: 0px !important;
}



.css-qiwgdb {
  padding: 16.5px 0px !important;
}
.css-182didf {
  padding: 8.5px 4px !important;
}
.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
  background: white;
  border-radius: 5px;
  margin: 3px 7px !important
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 5px !important;
    border: 1px $fc-grey-2 solid !important;
    border-radius: 5px !important;
    border: 1px $fc-grey-2 solid !important;
    background-color: #eee !important;
    color: $fc-black;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    box-shadow: none;
  }

  .accordion-body{
    border: 1px $fc-grey-2 solid !important;
    border-radius: 0px 0px 10px 10px !important;
    padding: 16px 10px !important;
  }



  .accordion-button::after{
    display: none !important;
  }
  
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  text-transform: none !important;
  min-height: 32px !important;
  color: #434E59 !important;
  line-height: 0.25 !important;
}
.css-1ujnqem-MuiTabs-root {
  min-height: 36px !important;
}
.css-1gsv261 {
  background-color: #F3F3F2 !important;
  border: 1px solid #DBDFE9 !important;
  border-radius: 5px !important;
  max-width: 230px !important;
}
.css-1wf8b0h-MuiTabs-flexContainer {
  justify-content: left !important;
}
.css-1aquho2-MuiTabs-indicator {
  height: 0px !important;
}
.MuiTabs-indicator{
  background-color: transparent !important;
}

// .MuiButtonBase-root-MuiRadio-root.Mui-checked {
//   color:$fc-blue-primary !important;
// }
//pagination margins
// .MuiTablePagination-selectLabel{
//   margin-top: 12px !important;
// }
// .MuiTablePagination-displayedRows{
//   margin-top: 15px !important;
// }
.MuiTypography-root {
  font-weight: 600 !important;
  font-size: 14px !important;
}
@media only screen and (min-width: 1400px){
   .container{
      max-width: 1140px !important;
  }
    }

@media only screen and (min-width: 1200px) and (max-width: 1399px){
  .container{
       max-width: 960px !important;
   }
   }

   @media only screen and (min-width: 992px) and (max-width: 1199px){
 .container{
      max-width: 960px !important;
  }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px){
    .container{
         max-width: 720px !important;
     }
     }
     input {
      border: 1.2px solid #A6A6A6;
      outline: none;
     }



    @media only screen and (max-width: 575.98px){
      .css-8nphli,
      .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .css-1yxmbwk,
      .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
        padding: 0 !important;
      }
      // .css-1oraz3g .MuiTablePagination-selectLabel {
      //   display: none !important;
      // }
      .css-1chpzqh.MuiTablePagination-selectLabel,
      .css-pdct74-MuiTablePagination-selectLabel {
        display: none;
      }

      .css-1oraz3g .MuiTablePagination-toolbar {
        flex-direction: row !important;
        align-items: center !important;
      }
      .css-h3u2z4-MuiTablePagination-root .MuiTablePagination-toolbar {
        -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
        align-items: center !important;
    }
  //   .css-h3u2z4-MuiTablePagination-root .MuiTablePagination-selectLabel {
  //     display: none !important;
  // }
  //   .css-twpmdu .MuiTablePagination-selectLabel {
  //     display: none;
  // }
  //   .MuiTablePagination-toolbar {
  //     display: flex;
  //     align-items: center;
  //     justify-content: space-between;
  //     // flex-wrap: wrap; 
  //     // padding: 0 10px; /* Adjust as needed */
  // }
  // .css-twpmdu .MuiTablePagination-select {
  //   margin-right: 16px !important;
  // }
  
  // .MuiTablePagination-spacer {
  //     flex-grow: 1; /* Allows the spacer to take available space */
  // }
  
  // .MuiTablePagination-selectLabel,
  // .MuiTablePagination-displayedRows {
  //     margin: 0 5px; /* Add some spacing */
  // }
  
  // .MuiTablePagination-actions {
  //     display: flex;
  //     gap: 5px; /* Spacing between action buttons */
  // }
  
  // select {
  //     margin: 0 5px; /* Add spacing around the select */
  // }
 
      .MuiTooltip-tooltip{
        max-width: 280px !important;
      }
      .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root, .css-zun73v, .css-zun73v-MuiButtonBase-root-MuiCheckbox-root {
        margin-left: 0px !important;
    }
      .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
        padding: 4px !important;
      }
      .MuiButtonBase-root.MuiTab-root {
        max-width: 106px !important;
        min-width: 106px !important;
        padding: 12px !important;
      }
      .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary{
        font-size: 12px !important;
        margin: 0 !important;
      }
      .MuiTabPanel-root {
        padding: 24px 0 !important;
    }
    }
   textarea{
    transition: border-color 0.3s ease;
   &:focus{
    border-color: #959595 !important;
    outline: none;
   }
  }
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
   padding: 16px !important;
  }
  .css-uhb5lp-MuiPaper-root-MuiDialog-paper{
    padding: 16px !important;
  }
  .css-uhb5lp{
    padding: 16px !important
  }
  
  .input:focus-visible {
    outline: none !important;
}
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 16.5px 0px !important;
}
.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8.5px 0px !important;
}
  .MuiTooltip-tooltip{
    max-width: max-content !important;
  }


  
  .MuiPaper-root {
    min-Width: '100%';
    // padding: 16px !important; // Ensure dropdown width matches the select box
  }
  
  .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    display: none !important;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid transparent !important
  }
  .custom-select{
    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
      height: 32.2px !important;
      // border: 1px solid #959595 !important
    }
  }
  
   
  @media only screen and (min-width: 425px) and  (max-width : 506px) and (orientation:portrait) {
    .mpb-20{
    padding-bottom: 20px !important;
    }
  }
    
  @media only screen and (min-width: 425px) and  (max-width : 506px) and (orientation:landscape) {
    .mpb-20{
    padding-bottom: 20px !important;
    }
  }
  .MuiFormControl-root {
    position: relative;  /* Ensure elements are positioned relative to their containers */
    z-index: 1;
    width: 100% !important;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    display: none !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-disabled{
  pointer-events: all !important;
}
.css-csobor.Mui-disabled {
  pointer-events: all !important;
  cursor: not-allowed !important;
}

.css-1ytlwq5-MuiAlert-icon, .css-1pxa9xg-MuiAlert-message, .css-ptiqhd-MuiSvgIcon-root{
  color: white !important;
}
.css-1cw4hi4-MuiAlert-icon, .css-1cw4hi4, .css-1xsto0d-MuiAlert-message, .css-1xsto0d, .css-1mzcepu-MuiSvgIcon-root, .css-1mzcepu{
  color: white !important;
}
// .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{
//   padding: 0px !important;
//   margin-left: 8px !important;
// }
// .css-zun73v-MuiButtonBase-root-MuiCheckbox-root{
//   padding: 0px !important;
//   margin-left: 8px !important;
// }
// .css-zun73v{
// padding: 0px !important;
// margin-left: 8px !important;
// }
.css-csobor {
  padding: 0 8px !important;
}
.css-m94tiq-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0 8px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  background: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
  height: 0px !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input, .css-1x5jdmq{
  padding: 10px 0px !important;
}
.css-twpmdu .MuiTablePagination-actions>button:disabled:hover, .MuiTablePagination-actions>button:disabled:hover{
  border: none !important;
}
.css-x0qxq3, .css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root{
  padding: 0px !important;
}



//<-------------------------retailer based buttons---------------------------------->

//<-----------------janie and jack starts------------------->

.janieandjack .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
  color: $fc-janieandjack !important;
}
.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary .janieandjack{
  color: $fc-black !important;
  font-weight: 500 !important;
  font-family: Inter !important;
  font-size: 14px !important;
  text-transform: none !important;
  margin: 0 15px !important;
  padding-bottom: 0px !important;
}
.janieandjack.form-select:focus{
  border-color:  $fc-janieandjack !important;
}
.janieandjack-menuitem.Mui-selected {
  background-color: $fc-janieandjack !important;
  color: $fc-white !important;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .acc-btn.show.janieandjack {
  background-color: $fc-janieandjack !important;
  color: $fc-white;
  border: 0px;
}
.janieandjack.table-page.MuiTablePagination-root .MuiTablePagination-select{
  display: flex !important;
  min-width: 24px !important;
}
// .janieandjackpagination.css-10by3a5-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem.Mui-selected.Mui-focusVisible {
//   background-color: $fc-janieandjack !important;
//   color: $fc-white !important;
// }
// .janieandjackpagination.css-10by3a5-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem.Mui-selected:hover {
//   background-color: $fc-janieandjack !important;
// }
// .janieandjackpagination.css-10by3a5-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem.Mui-selected {
//   background-color: $fc-janieandjack !important;
//   color: $fc-white !important;
// }
//<-----------------janie and jack ends------------------->

//<-----------------fluent-commerce starts------------------->
.fluentcommerce .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected  {
  color: $fc-fluentcommerce !important;
}
.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary .fluentcommerce{
  color: $fc-black !important;
  font-weight: 500 !important;
  font-family: Inter !important;
  font-size: 14px !important;
  text-transform: none !important;
  margin: 0 15px !important;
  padding-bottom: 0px !important;
}
.fluentcommerce.form-select:focus{
  border-color:  $fc-fluentcommerce !important;
}
.fluentcommerce-menuitem.Mui-selected {
  background-color: $fc-fluentcommerce !important;
  color: $fc-white !important;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .acc-btn.show.fluentcommerce {
  background-color: $fc-fluentcommerce !important;
  color: $fc-white;
  border: 0px;
}

.fluentcommerce.table-page.MuiTablePagination-root .MuiTablePagination-select{
  display: flex !important;
  min-width: 24px !important;
}
.fluentcommerce.table-page.css-10by3a5-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem.Mui-selected{
  background-color: $fc-fluentcommerce !important;
  color: $fc-white !important;
}


//<-----------------fluent-commerce ends------------------->


//<-----------------shop premium outlets starts------------------->
.shopsimon .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
  color: $fc-black !important;
}
.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary .shopsimon{
  color: $fc-black !important;
  font-weight: 500 !important;
  font-family: Inter !important;
  font-size: 14px !important;
  text-transform: none !important;
  margin: 0 15px !important;
  padding-bottom: 0px !important;
}
.shopsimon.form-select:focus{
  border-color:  $fc-shopsimon !important;
}
.shopsimon-menuitem.Mui-selected {
  background-color: $fc-shopsimon !important;
  color: $fc-white !important;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .acc-btn.show.shopsimon {
  background-color: $fc-shopsimon !important;
  color: $fc-white;
  border: 0px;
}
.shopsimon.table-page.MuiTablePagination-root .MuiTablePagination-select{
  display: flex !important;
  min-width: 24px !important;
}
.shopsimon.table-page.css-10by3a5-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem.Mui-selected{
  background-color: $fc-shopsimon !important;
  color: $fc-white !important;
}
//<-----------------shop premium outlets ends------------------->

//<-----------------Hatch collections starts------------------->
.hatchcollections .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
  color: $fc-black !important;
}
.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary .hatchcollections{
  color: $fc-black !important;
  font-weight: 500 !important;
  font-family: Inter !important;
  font-size: 14px !important;
  text-transform: none !important;
  margin: 0 15px !important;
  padding-bottom: 0px !important;
}
.hatchcollections.form-select:focus{
  border-color:  $fc-hatchcollections !important;
}
.hatchcollections-menuitem.Mui-selected {
  background-color: $fc-hatchcollections !important;
  color: $fc-white !important;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .acc-btn.show.hatchcollections {
  background-color: $fc-hatchcollections !important;
  color: $fc-white;
  border: 0px;
}

.hatchcollections.table-page.MuiTablePagination-root .MuiTablePagination-select{
  display: flex !important;
  min-width: 24px !important;
}
.hatchcollections.table-page.css-10by3a5-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem.Mui-selected{
  background-color: $fc-hatchcollections !important;
  color: $fc-white !important;
}
//<-----------------Hatch collections ends------------------->


//<-----------------wear-humans starts------------------->
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .acc-btn.show.wearhumans {
  background-color: $fc-yellow !important;
  color: $fc-black;
  border: 0px;
}

//<-----------------wear-humans ends------------------->


//<-----------------travel-pro starts------------------->
.travelpro .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
  color: $fc-travelpro !important;
}
.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary .travelpro{
  color: $fc-black !important;
  font-weight: 500 !important;
  font-family: Inter !important;
  font-size: 14px !important;
  text-transform: none !important;
  margin: 0 15px !important;
  padding-bottom: 0px !important;
}
.travelpro.form-select:focus{
  border-color:  $fc-travelpro !important;
}
.travelpro-menuitem.Mui-selected {
  background-color: $fc-travelpro !important;
  color: $fc-white !important;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .acc-btn.show.travelpro {
  background-color: $fc-travelpro !important;
  color: $fc-white;
  border: 0px;
}
.travelpro.table-page.MuiTablePagination-root .MuiTablePagination-select{
  display: flex !important;
  min-width: 24px !important;
}
.travelpro.table-page.css-10by3a5-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem.Mui-selected{
  background-color: $fc-travelpro !important;
  color: $fc-white !important;
}
//<-----------------travel-pro ends------------------->







// .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate{
//   color: $fc-janieandjack !important;
// }

// @media only screen and (min-width: 360px) and (max-width: 932px) and (orientation: portrait){
  //   .css-1e7cuzv-MuiFormControl-root {
  //     margin: 0px !important;
  //   }
  //   .custom-select{
  //     .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  //       height: 35px !important;
  //     }
  //   }
  //   .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  //   height: 44.5px !important;
  //   }
  // }
  // @media only screen and (min-width: 360px) and (max-width: 932px) and (orientation: landscape){
  //   .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  //     height: 42.5px !important;
  //     }
  //     .css-1e7cuzv-MuiFormControl-root {
  //       margin: 0px !important;
  //     }
  // }
  // @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
  //   .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  //   height: 43.2px !important;
  //   }
  //   .css-1e7cuzv-MuiFormControl-root {
  //     margin: 0px !important;
  //   }
  // }

  // li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
  //   background-color: #1976D2 !important;
  // }
  // .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  //   // width: 14vw !important;
  // height: 42.5px !important;
  // color: $fc-janieandjack !important;
  // }

  //   @media only screen and (min-width: 992px){
//  .container{
//     max-width: 960px !important;
// }
//   }

  @media only screen and (max-width: 767.98px){
    .container{
        width: 100% !important;
    }
    }

//textarea

  // textarea{
  //   resize: none !important;
  // }
  //tabs css
  // .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary{
  //   padding-bottom: 0px !important;
  // }



  // .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected{
  //   color: $fc-janieandjack !important;
  //   font-weight: 600 !important;
  //   font-family: Inter;
  // }

  // .css-1km1ehz.Mui-selected:hover {
//   background-color: $fc-janieandjack !important;
// }
// li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
//   background-color: $fc-janieandjack !important;
//   color: $fc-white !important
// }

// .css-zun73v.Mui-checked, .css-zun73v.MuiCheckbox-indeterminate {
//   color: $fc-janieandjack !important;
// }