@import "../styles/variables";

 .return-portal-wrapper {
// a {
//  color: $fc-blue-primary!important;
// } 
.br-1{
  border: 2px solid #A6A6A6;
  border-radius: 25px;
  height: 419px;
  width: 400px;
}
.img-wrap{
  text-align: end;
}
.img-hgt{
  width: 400px;
  // height: 420px;
  border-radius: 25px;
}

// .resend-color{
//   color: #F97608 !important;
// }

@media only screen and (max-width: 575.98px) {
  .img-hgt{
    height: auto;
    width: 290px;
  }
  .br-1{
    height: auto;
    width: 290px;
  }
  .img-wrap{
    text-align: center;
  }
}
@media only screen and (max-width: 767.98px) {
  .right-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

 @media only screen and (max-width: 767px) and (min-width: 575px){
  .img-hgt{
    height: auto;
    width: 450px;
  }
  .br-1{
    height: auto;
    width: 450px;
  }
  .img-wrap{
    text-align: center;
  }
}
@media screen and (max-width: 991.98px) and (min-width: 768px) {
 .img-hgt{
   height: 370px;
   width: 350px;
 }
 .br-1{
   height: 370px;
   width: 350px;
 }
 .img-wrap{
   text-align: center;
 }
 .mt-2rem {
  margin-top: 0 !important;
 }
 .return-text {
  margin: 0;
 }
}
// .janieandjack.black-btn {
//   border: 1px solid #1b375d;
//   background-color: #1b375d !important;
//     // padding: 5px 20px 5px 20px !important;
// }

// .janieandjack.black-btn-1 {
//   border: 1px solid #1b375d;
//   background-color: #1b375d !important;
//     padding: 20px 20px 15px 20px !important;
// } 
.shopoutletpremium.black-btn {
    border: 1px solid $fc-black;
    background-color: $fc-black !important;
      // padding: 5px 20px 5px 20px !important;
  }

 .wearhumans .textcolor{
  color: #000 !important ;
 }

 .janieandjack .textcolor{
  color: #FFFFFF !important;
 }

 .hatchcollections .textcolor{
  color: #FFFFFF !important;
 }
  
.shopoutletpremium.black-btn-1 {
    border: 1px solid $fc-black;
    background-color: $fc-black !important;
    padding: 20px 20px 15px 20px !important;
  } 
  .wearhumans.black-btn {
    border: 1px solid $fc-yellow;
    background-color: $fc-yellow !important;
      // padding: 5px 20px 5px 20px !important;
  }
  
.wearhumans.black-btn-1 {
    border: 1px solid $fc-yellow;
    background-color: $fc-yellow !important;
      padding: 20px 20px 15px 20px !important;
  } 
  .travelpro.black-btn-1{
    border: 1px solid #1e284b !important;
    background-color: #1e284b !important;
    color: white !important;
  }

 @media screen and (max-width: 1199px) and (min-width: 992px) {
//   .paddingx-4{
//     padding-left: 4.5rem !important; 
//     padding-right: 3rem !important;
//   }

//   .pl-4rem{
//     padding-left: 3rem;
//   }
  .br-1 {
    margin-left: 16px;
  }
 }
 @media only screen and (min-width: 1400px) {
//   .mb-20 {
//     margin-bottom: 25px !important;
//   }
//   .mt-3rem{
//     margin-top: 3rem !important;
//   }
  // .br-1{
  //   height: 419px !important;
  //   width: 400px;
  // }
  // .img-hgt{
  //   width: 400px;
  //   height: 420px;
  // }
  // .img-wrap{
  //   text-align: end;
  // }
//   .paddingx-4{
//     padding-left: 2.5rem !important;
//     padding-right: 3rem !important;
//   }
//   .pl-4rem{
//     padding-left: 9rem;
//   }
 }
}