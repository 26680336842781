.shop-btn {
    min-width: 375px !important;
    height: 45px;
}
.download-btn {
    min-width: 275px !important;
}

.sucess-return{
    // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #08C258;
    font-size: 22px !important;
}
.lh-30{
    line-height: 30px;
}

@media only screen and (max-width: 575px) {
    .shop-btn {
        min-width: 190px !important;
    }
    .download-btn {
        min-width: 190px !important;
    }
}