@import './../../../styles/variables.scss';
@import './../../../styles/utilities';

.my-orders-wrapper {
    .checkbox-width{
        max-width: 4% !important;
    }
    .qtyw{
        width: 75px !important;
    }
    .qty-wrap {
        .form-select{
            padding: 5px !important;
            height: 25px !important;
            width: 56px !important;
            border: 1px solid #959595;
            --bs-form-select-bg-img: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill="none" stroke='%23959595' stroke-width='1' d='m2 5 6 6 6-6'/%3E%3C/svg%3E) !important;
        }
    }

    @media only screen and (max-width:575.98px) {
    .img-block{
        margin-left: 5px !important;
        width: 50px !important;
       }
    }
}

/* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  /* Transparent Overlay */
  .loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    //background: rgba(0,0,0,0.8);
    //backdrop-filter: saturate(180%) blur(10px);
    // background: radial-gradient(rgba(18, 12, 12, 0.8), rgba(0, 0, 0, .8));
    // background: -webkit-radial-gradient(rgba(11, 8, 8, 0.8), rgba(0, 0, 0,.8));
    }

.shopsimon .prymary-btn{
    background-color: black !important;
    color: white !important;
}

.border-image{
  border: 1px solid #DBDFE9;
  border-radius: 12px;
  padding: 5px;
}
.margintop-4{
  margin-top: 4px !important;
}
.line{
  border-bottom:1px solid #DBDFE9 !important ;
}

@media only screen and (max-width: 387.98px){
  .past-days-wrapper {
  .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
        left: 13px !important;
      }
  }
}